import { AxiosError, AxiosResponse } from 'axios';
import { isBefore, parseISO, subMinutes } from 'date-fns';
import { logError } from 'features/logging/logError';
import { logsEndpointUrl } from 'features/logging/sendLogToApi';
import { transactionIdHeaderName } from 'features/logging/transactionIdHeaderName';
import { isObject } from 'utils/isObject';

const getResponseStatusValue = (response?: AxiosResponse) => {
  const status = response?.status;
  return status ? String(status) : 'no-response';
};

const getResponseData = (response?: AxiosResponse) => {
  try {
    const data = response?.data;
    if (data) {
      return JSON.stringify(data);
    }
  } catch {
    /* empty on purpose */
  }

  return null;
};

const getRequestData = (response?: AxiosResponse) => {
  try {
    const data = response?.config.data;
    if (data) {
      return JSON.stringify(data);
    }
  } catch {
    /* empty on purpose */
  }

  return null;
};

/**
 * Tries to get the x-trans-id from the axios request configuration,
 * which is added to the error thrown by axios
 */
export const tryGetTransactionIdFromAxiosError = (error: AxiosError) => {
  if (isObject(error.config?.headers)) {
    const transactionIdHeader = error.config?.headers[transactionIdHeaderName];
    if (typeof transactionIdHeader === 'string' && transactionIdHeader.length > 0) {
      return transactionIdHeader;
    }
  }

  return undefined;
};

/**
 * Throttles 403 errors to avoid spamming the logs
 */
const shouldLog403Error = (error: AxiosError) => {
  const cacheKey = `error_log_throttle_403_${error.config?.url || 'general'}`;
  const lastLogTimeString = localStorage.getItem(cacheKey);
  const lastLogTime = lastLogTimeString ? parseISO(lastLogTimeString) : null;
  // Log only once every 10 minutes
  const date10MinAgo = subMinutes(new Date(), 10);

  if (!lastLogTime || isBefore(lastLogTime, date10MinAgo)) {
    localStorage.setItem(cacheKey, new Date().toISOString());
    return true;
  }

  return false;
};

const isErrorIgnored = (
  error: AxiosError,
  url: string | undefined,
  responseString: string | null
) => {
  // Ignore errors about a canceled request
  if (error.name === 'CanceledError') {
    return true;
  }

  // Ignore general network and aborted issues
  if (
    [
      'network error',
      'axios: network error',
      'axioserror: network error',
      'request aborted',
      'timeout exceeded',
      'axioserror: timeout exceeded'
    ].includes(error.message.toLowerCase())
  ) {
    return true;
  }

  // Ignore certain errors
  if (
    url?.includes('/customer/email-available') &&
    (responseString?.includes('Disposable email addresses are not allowed') ||
      responseString?.includes('The email must be a valid email address') ||
      responseString?.includes('The email has already been taken'))
  ) {
    return true;
  }

  // Ignore input validations in modular workflow
  if (url?.includes('/brand-hub/modular-workflow/run/') && error.status === 422) {
    return true;
  }

  return false;
};

export const logAxiosError = (error: AxiosError) => {
  const url = error.config?.url;
  const method = error.config?.method;
  const responseStatus = getResponseStatusValue(error.response);
  // TODO: This might contain sensitive data, so we should filter or mask it
  const responseData = getResponseData(error.response);
  const requestData = getRequestData(error.response);

  // Errors, which are not useful to log in general
  // - errors about using the logs endpoint itself
  // - unauthorized errors
  if (url === logsEndpointUrl || Number(responseStatus) === 401) {
    // eslint-disable-next-line no-console
    console.error(error);
    return;
  }

  if (isErrorIgnored(error, url, responseData)) {
    return;
  }

  // Log 403 throttled
  if (Number(responseStatus) === 403 && !shouldLog403Error(error)) {
    return;
  }

  return logError(error, 'http', {
    'meta.http.url': url,
    'meta.http.method': method,
    'meta.http.status': responseStatus,
    'meta.http.requestData': requestData,
    'meta.http.response': responseData,
    'meta.http.transactionId': tryGetTransactionIdFromAxiosError(error)
  });
};
